'use client';

import { memo } from 'react';
import { TableCellOptions, TableProps } from './types';
import { getCellClassName } from './util';
import get from 'lodash/get';
import { cn } from '../../utils/cn';
import { useTableContext } from './table-provider';
export type TableCellProps<T> = TableCellOptions<T> & Pick<TableProps<T>, 'className' | 'stickyLeftClassName' | 'compact'> & {
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  rowSpan?: number;
};
function _TableCell<T>(props: TableCellProps<T>) {
  const {
    column,
    columnIndex,
    rowData,
    rowIndex,
    rowSpan
  } = props;
  const {
    sort
  } = useTableContext<T>();
  const isSorted = sort?.column === column;
  const cellProps = {
    rowData,
    column,
    columnIndex,
    rowIndex,
    rowSpan
  };
  return <td width={column.width} className={cn('dark:border-coolgray-mid', getCellClassName(props, 'cell'), {
    'bg-gray-100': isSorted
  })} rowSpan={rowSpan} style={{
    ...((typeof column.cellStyle === 'function' ? column.cellStyle(cellProps) : column.cellStyle) ?? {}),
    ...(typeof column.width === 'number' ? {
      minWidth: column.width
    } : undefined)
  }} data-sentry-component="_TableCell" data-sentry-source-file="table-cell.tsx">
      {column.cellRenderer?.(cellProps) ?? String((column.key ? get(rowData, column.key, null) : null) ?? '-')}
    </td>;
}
export const TableCell = memo(_TableCell) as typeof _TableCell;